<template>
  <div class="refer-to pa-6 mx-6">
    <h1 class="my-2">{{ translations.hello }} {{ userInfo && userInfo.account ? userInfo.account.name : '' }}</h1>

    <div class="my-6" style="max-width: 75%">
      <refer-to-svg v-if="hasEmployee" />
      <warning-svg v-else :brandColor="$vuetify.theme.themes.light.main" />
    </div>

    <h3 class="pa-4">{{ translations[hasEmployee ? 'accountIsAnEmployee' : 'accountMissingConfig'] }}</h3>

    <p class="my-2">{{ translations[hasEmployee ? 'accountIsAnEmployeeReferTo' : 'accountMissingConfigReferTo'] }}</p>

    <template v-if="hasEmployee">
      <v-btn color="main" class="white--text" @click="redirectToAdmin">{{ translations.goToEmployeeApp }}</v-btn>
      <v-btn color="main" class="mt-5 white--text" text @click="goToLogin">{{ translations.backToLogin }}</v-btn>
    </template>
    <v-btn color="main" class="mt-5 white--text" @click="goToLogin" v-else>{{ translations.backToLogin }}</v-btn>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import ReferToSvg from '../assets/ReferToSvg.vue'
import WarningSvg from '../assets/WarningSvg.vue'
import { onLogout } from '@/vue-apollo'
import conf from '../utils/api/conf'

export default {
  name: 'ReferTo',

  components: {
    ReferToSvg,
    WarningSvg
  },

  computed: {
    translations: () => translations,

    ...mapState({
      userInfo: state => state.auth.userInfo
    }),

    hasEmployee() {
      return this.userInfo?.employee
    }
  },

  methods: {
    async goToLogin() {
      await onLogout()
      await this.$router.push({ name: 'Login' })
    },

    async redirectToAdmin() {
      await this.goToLogin()
      window.open(conf.value('VUE_APP_REDIRECT_URL'))
    }
  }
}
</script>


<style>
.refer-to {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
